
* {
    box-sizing: border-box;
    margin: auto;
    padding: 0;
    font-family: Lato, sans-serif;
    text-decoration: none;
    
}

